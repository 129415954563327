function getDate(val,type){
  // console.log(val)
  let year = val.getFullYear()
  let month = val.getMonth()+1
  let date = val.getDate()
  let hour = val.getHours()
  let minite = val.getMinutes()
  let seconds = val.getSeconds()
  if(month.toString().length<2){
    month = '0'+month
  }
  if(date.toString().length<2){
    date = '0'+date
  }
  if(hour.toString().length<2){
    hour = '0'+hour
  }
  if(minite.toString().length<2){
    minite = '0'+minite
  } 
  if(seconds.toString().length<2){
    seconds = '0'+seconds
  } 
 if(type == 0 ){
  return month+'-'+date+" " + hour + ':' + minite 
 }else if(type == 1){
  return year+'-'+month
 }else {
    return year+'-'+month+'-'+date
}
 }
  // return year+'-'+month+'-'+date+" " + hour + ':' + minite + ":" + seconds
 
export default getDate
